import { Colors, TitleH1 } from "@components/global.styles"
import * as React from "react"
import { Container } from "./styles"

const NotFound = () => {
  return (
    <Container>
      <TitleH1 className="four-title" colors={Colors.pink}>
        404
      </TitleH1>
      <TitleH1 className="four-not" colors={Colors.lightBackground}>
        Not Found
      </TitleH1>
    </Container>
  )
}

export default NotFound
